*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  height: 100%;
  font-size: 62.5%;
}

body {
  text-align: center;
  background-color: #000;
  background-size: 200% 200%;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: distant_galaxyregular, Arial, sans-serif;
  line-height: 1.5;
  animation: .9s ease-out forwards scrollToBottom;
  display: flex;
  position: relative;
  overflow: hidden scroll;
}

@keyframes scrollToBottom {
  0% {
    overflow-y: hidden;
    transform: translateY(-100%);
  }

  100% {
    overflow-y: scroll;
    transform: translateY(0);
  }
}

.avatar {
  width: 20vw;
  max-width: 275px;
  height: 20vw;
  max-height: 275px;
  margin-bottom: 20px;
}

.tagline, .intro {
  margin: 10px 0;
  font-size: 1.5rem;
}

.blurNav {
  filter: blur(10px);
}

.nav__links {
  transition: all .3s ease-in;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility .3s ease-in-out .3s ease-in-out, opacity .3s ease-in-out;
}

@font-face {
  font-family: distant_galaxyregular;
  src: url("distgrg_-webfont.214aa68b.woff2") format("woff2"), url("distgrg_-webfont.b8c85cd9.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Fatface;
  src: url("AbrilFatface-Regular.8e285fbe.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato-regular;
  src: url("Lato-Regular.5d19d3f3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato-medium;
  src: url("Lato-Medium.410630fb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.title {
  font-family: distant_galaxyregular;
}

@media only screen and (width <= 75em) {
  html {
    font-size: 59%;
  }
}

@media only screen and (width <= 56.25em) {
  html {
    font-size: 56%;
  }
}

@media only screen and (width <= 37.5em) {
  html {
    font-size: 53%;
  }
}

.header {
  text-align: center;
  overflow: hidden;
}

.header__content {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 6rem;
  display: flex;
  overflow: hidden;
}

.nav_right {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

.header__logo-container {
  cursor: pointer;
  color: #e7f53c;
  align-items: center;
  transition: color .3s;
  display: flex;
}

.header__logo-img {
  width: 50px;
  height: auto;
}

.header__logo-sub {
  order: 1;
  font-size: 1.6rem;
}

.home-hero {
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: Lato-medium;
  display: flex;
}

.home-hero__cta {
  order: 2;
}

.home-hero__content {
  text-align: center;
  transform-style: preserve-3d;
  border-radius: 10px;
  width: 92%;
  max-width: 90rem;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate3d(0, 0, 0)scale3d(1, 1, 1)rotateX(0)rotateY(0)rotateZ(0)skew(0deg);
}

.home-hero__content h1 {
  margin-bottom: 10px;
  font-size: 2.9rem;
}

.home-hero__content p {
  margin-top: 0;
  font-size: 1.9rem;
}

.nav {
  padding: 20px 0;
  transition: transform 1s, opacity 1s;
}

.nav__links {
  justify-content: space-around;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.nav__link-wrapper {
  flex: 1;
  justify-content: center;
  margin: 0 15px;
  display: flex;
}

.nav__link {
  color: #e7f53c;
  padding: 10px;
  font-size: 3rem;
  text-decoration: none;
  transition: transform .3s ease-in-out, color .3s ease-in-out, font-size .3s ease-in-out;
  animation: 1s ease-in-out 2s forwards fadeInBlur;
  display: inline-block;
  position: relative;
}

.nav__link:hover {
  -webkit-text-stroke: 1px #e7f53c;
  color: #0000;
  transform-origin: 0;
  font-style: italic;
  transition: transform .3s, color .3s;
  transform: translate(15px);
}

.nav__link:after {
  content: "";
  transform-origin: 0;
  background-color: #e6f53c;
  width: 100%;
  height: 2px;
  transition: transform .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
}

.nav__link:hover:after {
  transform: scaleX(1);
}

.home-hero__socials {
  order: 1;
  align-items: center;
  display: flex;
}

.home-hero__social {
  background: #d9d9d91a;
  border: 1px solid #ffffff80;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  margin: 10px;
  transition: background .3s ease-in-out;
}

.home-hero__social-icon-link {
  border-radius: 50%;
  width: 100%;
  padding: .9rem;
  transition: background .3s;
  display: block;
}

.home-hero__social-icon-link:hover {
  background: #0fbaee1a;
  border-radius: 50%;
}

.home-hero__social-icon {
  width: 100%;
}

.heading-primary, .home-hero__info {
  color: #f5f5f5;
}

.btn {
  color: #0000;
  text-transform: uppercase;
  letter-spacing: .125rem;
  -webkit-text-stroke: .0625rem #333;
  white-space: nowrap;
  background: #e7f53c;
  border: .125rem solid #0000;
  border-radius: 1.25rem;
  order: 2;
  font-weight: 700;
  text-decoration: none;
  transition: transform .3s, color .3s;
  display: inline-block;
  box-shadow: 0 .3125rem .9375rem #00000026;
}

.btn:hover {
  color: #333;
  border: 2px solid #333;
  transform: translateY(-3px);
}

.btn--bg {
  padding: 1rem 2rem;
  font-size: 2rem;
}

.movable {
  visibility: visible;
  opacity: 1;
  transition: visibility .3s, opacity .3s linear;
}

.main-container__footer {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  margin: 1rem auto;
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.main-footer {
  color: #fff;
  z-index: 3;
  background: #000;
  width: 100%;
  position: relative;
}

.main-footer__upper {
  justify-content: space-between;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 3rem 0;
  display: flex;
}

.main-footer__row-1 {
  order: 2;
  width: 30%;
}

.main-footer__row-2 {
  order: 1;
  width: 30%;
  max-width: 50rem;
}

.main-footer__description a {
  color: inherit;
  margin-top: 2rem;
  font-family: lato-regular;
  font-size: 1.5rem;
  line-height: 1.7;
  text-decoration: none;
}

.main-footer__description a:hover {
  text-decoration: underline;
}

.main-footer__social-cont {
  margin-top: 2rem;
}

.main-footer__icon {
  width: 2.5rem;
  margin-right: 1rem;
}

.main-footer__lower {
  color: #eee;
  text-align: left;
  text-align: center;
  border-top: 1px solid #444;
  padding: 2rem 0;
  font-size: 1.2rem;
}

.heading-2 {
  text-transform: uppercase;
  font-family: lato-medium;
}

.heading-4 {
  font-family: lato-medium;
}

.main-footer__lower a {
  margin-left: 2px;
  font-weight: bold;
  text-decoration: underline;
}

.header__sm-menu--active {
  visibility: hidden;
  opacity: 0;
}

.d-none {
  display: none;
}

.header__sm-menu {
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  background: #fff;
  width: 100%;
  transition: all .3s;
  position: absolute;
  box-shadow: 0 5px 5px #0000001a;
}

.header__sm-menu-link a {
  color: #333;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid #eee;
  padding: 2.5rem 3rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  transition: color .3s;
  display: block;
}

#particles-js {
  z-index: 0;
  background: radial-gradient(circle at 12% 81%, #000, #3b3334, #2f0c0c);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.header__content > :not(#particles-js) {
  z-index: 1;
  position: relative;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.contact {
  z-index: 3;
  background-position: center;
  flex: 1;
  font-family: Lato-medium;
  display: flex;
  position: relative;
}

.contact__form-container {
  text-align: right;
  background: #fff;
  border-radius: 5px;
  width: 95%;
  max-width: 80rem;
  margin: 5rem auto 3rem;
  padding: 4rem;
  box-shadow: 0 0 10px #0000001a;
}

.contact__form-field {
  margin-bottom: 4rem;
}

.contact__form-label {
  color: #666;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  display: block;
}

.contact__form-input {
  color: #333;
  letter-spacing: 0;
  background: #f0f0f0;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact__form-input:-ms-input-placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact__form-input::-moz-placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact__form-input:-moz-placeholder {
  opacity: 1;
  color: #999;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact__form-input::-moz-placeholder {
  opacity: 1;
  color: #999;
  font-size: 1.6rem;
  font-weight: 600;
}

.contact__btn {
  padding: 2rem 4rem;
  font-family: distant_galaxyregular;
  font-size: 3rem;
}

.contact__btn:hover {
  border: 2px solid #999;
}

.main-container {
  width: 100%;
  height: 100%;
  margin: 5rem auto 1rem;
  transition: transform 1s, opacity 1s;
}

.heading-sec__main {
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  font-size: 4rem;
  display: block;
  position: relative;
}

.heading-sec__main--lt {
  color: #fff;
}

.heading-sec__sub {
  color: #fff;
  justify-content: center;
  margin: auto 2rem;
  font-family: Lato-regular;
  font-weight: 400;
  display: flex;
}

.about-section h3, .about-section h2 {
  color: #0000;
  -webkit-text-stroke: 1px #333;
  font-family: distant_galaxyregular;
}

.about-section {
  z-index: 3;
  background-color: #e0d1d1;
  padding: 40px 20px;
  font-family: Lato-medium;
}

.about-h2 {
  text-align: left;
  margin-bottom: 1.25rem;
  font-size: 10rem;
}

.about-content {
  border-bottom: 1px solid #eee;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 2rem;
  display: flex;
}

.introduction {
  flex: 1;
  margin-right: 1rem;
  font-family: Lato-medium;
  font-size: 2rem;
}

.about-image {
  flex: 1;
}

.skills {
  flex-wrap: wrap;
  flex: 2;
  order: 1;
  display: flex;
}

.skills__skill {
  color: #777;
  background: #9993;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 600;
}

.soft {
  background: #e6f53c;
}

.skills-section h3 {
  text-align: left;
  flex: 1;
  order: 2;
  align-items: flex-end;
  margin-bottom: 1.25rem;
  margin-right: 2rem;
  font-size: 5rem;
  font-weight: bold;
}

.skills-section {
  border-bottom: 1px solid #eee;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.25rem;
  display: flex;
}

.about-skills-h3 {
  text-align: justify;
}

.about-skills-h3 span {
  text-align: center;
  display: block;
}

.approach, .goals, .personal-interests {
  border-bottom: 1px solid #eee;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 6rem auto;
  display: flex;
}

.approach h3, .personal-interests h3 {
  text-align: left;
  flex: 1;
  margin-bottom: 1.25rem;
  font-size: 5rem;
  font-weight: bold;
}

.approach p, .personal-interests p {
  flex: 2;
  margin-left: 2rem;
  font-size: 2rem;
}

.goals h3 {
  text-align: left;
  flex: 1;
  margin-bottom: 1.25rem;
  font-size: 5rem;
  font-weight: bold;
}

.goals p {
  flex: 2;
  margin-right: 2rem;
  font-size: 2rem;
}

.about-cta__btn-container {
  text-align: center;
  margin-top: 2rem;
  font-family: distant_galaxyregular;
}

.projects-section {
  background: url("giphy.aceb6191.webp") center / cover no-repeat;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  display: flex;
}

.coming-soon-banner, .projects-section h2 {
  display: none;
}

@media only screen and (width >= 56.25em) {
  .nav {
    max-height: 110px;
  }

  .home-hero {
    height: calc(100vh - 238px);
    min-height: 400px;
  }

  .header__content-other {
    display: flex;
  }

  .header__content-home {
    height: 14vh;
    overflow: hidden;
  }

  .header__logo-sub {
    font-size: 2.5rem;
  }
}

@media only screen and (width >= 37.5em) {
  .header__main-ham-menu-cont {
    width: 3rem;
    padding: 2.2rem 0;
    display: none;
  }

  .nav-header {
    order: 2;
  }

  .header__main-ham-menu-cont {
    order: 5;
  }

  .header__logo-container {
    order: 0;
  }

  .home-hero__cta {
    order: 3;
  }

  .home-hero__social {
    order: 1;
  }

  .goals h3 {
    order: 2;
  }

  .goals p {
    order: 1;
  }
}

@media only screen and (width <= 75em) and (width >= 56.25em) {
  .home-hero {
    display: flex;
  }

  .avatar {
    width: 25%;
    height: 25%;
  }
}

@media only screen and (width <= 56.25em) and (width >= 37.5em) {
  body {
    font-size: 1rem;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .home-hero {
    height: calc(100vh - 100px);
  }

  .avatar {
    width: 14vw;
    height: 14vw;
  }

  .home-hero__cta {
    font-size: 16px;
  }

  .btn {
    font-size: 14px;
  }

  .nav {
    height: auto;
    padding: 0%;
  }
}

@media only screen and (width <= 56.25em) {
  .main-footer__upper {
    margin-left: 5rem;
    margin-right: 5rem;
    padding: 1rem 0;
  }

  .main-footer__lower {
    padding: 1.5rem 0;
  }

  .movable {
    display: none;
  }
}

@media only screen and (width <= 37.5em) {
  body {
    flex-direction: column;
    display: flex;
  }

  .header {
    flex-direction: column;
    flex: 1;
    align-items: center;
  }

  .nav {
    flex: .5;
    padding: 0;
  }

  .home-hero__socials {
    justify-content: center;
    align-items: center;
  }

  .home-hero {
    flex: 2;
  }

  .home-hero__social {
    width: 30px;
    height: 30px;
  }

  .home-hero__social-icon-link {
    padding: 16%;
    font-size: 100%;
  }

  .header__sm-menu--active {
    visibility: visible;
    opacity: 1;
  }

  .nav__links {
    display: none;
  }

  .avatar {
    width: 50%;
    height: 50%;
  }

  .header__content {
    height: 100%;
    padding: .5rem 2rem;
  }

  .header__logo-sub {
    font-size: 2.5rem;
  }

  .header__main-ham-menu-cont {
    display: block;
  }

  .contact__form-container {
    padding: 3rem;
  }

  .header__main {
    order: 3;
  }

  .header__logo-container {
    order: 0;
  }

  .home-hero__cta {
    order: 2;
  }

  .home-hero__social {
    order: 1;
  }

  .approach, .goals, .personal-interests, .about-cta, .about-content, .skills-section {
    flex-direction: column;
    align-items: center;
  }

  .introduction, .about-image, .skills {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .about-h2, .introduction, .approach h3, .goals h3, .personal-interests h3, .about-cta h3 {
    text-align: center;
  }

  .skills {
    order: 2;
    justify-content: center;
    margin-right: 0;
  }

  .skills-section h3 {
    order: 1;
  }

  .approach h3, .approach p {
    text-align: center;
    margin-left: 0;
  }

  .approach p, .goals p, .personal-interests p, .about-cta p {
    text-align: center;
    margin: 0 0 1rem;
  }
}

:root {
  --background-gradient: radial-gradient(circle at 12% 81%, #000, #3b3334, #2f0c0c);
}
/*# sourceMappingURL=index.e80d650d.css.map */
