/* Reset and base styles */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 10px */
  scroll-behavior: smooth;
  height: 100%;
}

body {
  /* font-family: "distant_galaxyregular", Arial, sans-serif; */
  font-family: "distant_galaxyregular", Arial, sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: #000000;
  position: relative;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: scroll;
  max-width: 100vw;
  /* height: 100%; */
  animation: scrollToBottom 0.9s ease-out forwards;
  /* background: linear-gradient(135deg, #2e2e2e, #1c1c1c); */
  background-size: 200% 200%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes scrollToBottom {
  0% {
    overflow-y: hidden;
    transform: translateY(-100%);
  }
  100% {
    overflow-y: scroll;
    transform: translateY(0);
  }
}

.avatar {
  width: 20vw;
  height: 20vw;
  max-width: 275px;
  max-height: 275px;
  /* border-radius: 50%; */
  margin-bottom: 20px;
  /* background-color: #14b5ca; */
}
.tagline,
.intro {
  font-size: 1.5rem;
  margin: 10px 0;
}
.blurNav {
  filter: blur(10px);
}
.nav__links {
  transition: 0.3s ease-in;
}
.hidden {
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s ease-in-out 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
}
/* ======================================
   Font-face
   ====================================== */
@font-face {
  font-family: "distant_galaxyregular";
  src: url("../assets/fonts/distgrg_-webfont.woff2") format("woff2"),
    url("../assets/fonts/distgrg_-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Fatface";
  src: url("../assets/fonts/AbrilFatface-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-regular";
  src: url("../assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-medium";
  src: url("../assets/fonts/Lato-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.title {
  font-family: "distant_galaxyregular";
}
/* ======================================
   Responsive font sizing 
   ====================================== */
@media only screen and (max-width: 75em) {
  html {
    font-size: 59%; /* ~9.44px */
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 56%; /* ~8.96px */
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 53%; /* ~8.48px */
  }
}
/* ======================================
   Header
   ====================================== */
.header {
  overflow: hidden;
  text-align: center;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 6rem;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;
}

.nav_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-shrink: 0;
}

.header__logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #e7f53c;
  transition: color 0.3s;
}

.header__logo-img {
  width: 50px;
  height: auto;
}

.header__logo-sub {
  font-size: 1.6rem;
  order: 1;
}

.home-hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-family: "Lato-medium";
}
.home-hero__cta {
  order: 2;
}

.home-hero__content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90rem;
  width: 92%;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.home-hero__content h1 {
  margin-bottom: 10px;
  font-size: 2.9rem;
}

.home-hero__content p {
  margin-top: 0;
  font-size: 1.9rem;
}

.nav {
  padding: 20px 0;

  transition: transform 1s, opacity 1s;
}

.nav__links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.nav__link-wrapper {
  margin: 0 15px;
  flex: 1;
  display: flex;
  justify-content: center;
}

.nav__link {
  color: #e7f53c;
  text-decoration: none;
  font-size: 3rem;
  display: inline-block;
  padding: 10px;
  position: relative;
  animation: fadeInBlur 1s ease-in-out forwards 2s;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out,
    font-size 0.3s ease-in-out;
}

.nav__link:hover {
  -webkit-text-stroke: 1px #e7f53c;
  color: transparent;
  transition: transform 0.3s, color 0.3s;
  transform-origin: left;
  transform: translate(15px);
  font-style: italic;
}
.nav__link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #e6f53c;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s;
}
.nav__link:hover::after {
  transform: scaleX(1);
}

/* ======================================
   Social Icons
   ====================================== */
.home-hero__socials {
  order: 3;
  align-items: center;
  display: flex;
  order: 1;
}

.home-hero__social {
  margin: 10px;
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transition: background 0.3s ease-in-out;
}

.home-hero__social-icon-link {
  width: 100%;
  display: block;
  padding: 0.9rem;
  transition: background 0.3s;
  border-radius: 50%;
}

.home-hero__social-icon-link:hover {
  background: rgba(15, 186, 238, 0.1);
  border-radius: 50%;
}

.home-hero__social-icon {
  width: 100%;
}

.heading-primary {
  color: #f5f5f5;
}

.home-hero__info {
  color: #f5f5f5;
}
/* ======================================
   Btn Styles
   ====================================== */

.btn {
  background: #e7f53c;
  color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.125rem; /* 2px */
  display: inline-block;
  font-weight: 700;
  border-radius: 1.25rem; /* 20px  */
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.15); /* 5px and 15px  */
  transition: transform 0.3s, color 0.3s;
  -webkit-text-stroke: 0.0625rem #333; /* 1px  */
  border: 0.125rem solid transparent; /* 2px  */
  text-decoration: none;
  order: 2;
  white-space: nowrap;
}
.btn:hover {
  transform: translateY(-3px);
  color: #333;
  border: 2px solid #333;
}
.btn--bg {
  padding: 1rem 2rem;
  font-size: 2rem;
}
.movable {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, opacity 0.3s linear;
}

/* ======================================
   Footer
   ====================================== */
.main-container__footer {
  margin: auto;
  width: 100%;
  height: 100%;
  transition: transform 1s, opacity 1s;
  margin: 1rem auto 1rem auto;
  flex-shrink: 0;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}
.main-footer {
  background: #000;
  color: #fff;
  z-index: 3;
  position: relative;
  width: 100%;
}
.main-footer__upper {
  display: flex;
  justify-content: space-between;
  padding: 3rem 0;
  margin-left: 5rem;
  margin-right: 5rem;
}
.main-footer__row-1 {
  order: 2;
  width: 30%;
}
.main-footer__row-2 {
  width: 30%;
  order: 1;
  max-width: 50rem;
}
.main-footer__description a {
  margin-top: 2rem;
  color: inherit;
  font-size: 1.5rem;
  line-height: 1.7;
  font-family: "lato-regular";
  text-decoration: none;
}
.main-footer__description a:hover {
  text-decoration: underline;
}
.main-footer__social-cont {
  margin-top: 2rem;
}
.main-footer__icon {
  margin-right: 1rem;
  width: 2.5rem;
}
.main-footer__icon--mr-none {
  margin-right: 0;
}
.main-footer__lower {
  padding: 2rem 0;
  border-top: 1px solid #444;
  color: #eee;
  font-size: 1.2rem;
  text-align: left;
  text-align: center;
}
.heading-2 {
  font-family: "lato-medium";
  text-transform: uppercase;
}
.heading-4 {
  font-family: "lato-medium";
}
.main-footer__lower a {
  text-decoration: underline;
  font-weight: bold;
  margin-left: 2px;
}
/* ======================================
   Small Menu
   ====================================== */
.header__sm-menu--active {
  visibility: hidden;
  opacity: 0;
}
.d-none {
  display: none;
}
.header__sm-menu {
  background: #fff;
  position: absolute;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}
.header__sm-menu-link a {
  display: block;
  text-decoration: none;
  padding: 2.5rem 3rem;
  font-size: 1.6rem;
  color: #333;
  text-align: right;
  border-bottom: 1px solid #eee;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: color 0.3s;
}
/* ======================================
   Particle-js
   ====================================== */
#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: radial-gradient(
    circle at 12% 81%,
    rgba(0, 0, 0, 1),
    rgb(59, 51, 52),
    rgba(47, 12, 12, 1)
  );
  z-index: 0;
}
.header__content > *:not(#particles-js) {
  z-index: 1;
  position: relative;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
/* ======================================
   Contact Form
   ====================================== */
.contact {
  background-position: center;
  z-index: 3;
  font-family: "Lato-medium";
  position: relative;
  flex: 1;
  display: flex;
}
.contact__form-container {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: white;
  padding: 4rem;
  margin-top: 6rem;
  max-width: 80rem;
  text-align: right;
  width: 95%;
  border-radius: 5px;
  margin: 5rem auto 3rem auto;
}
.contact__form-field {
  margin-bottom: 4rem;
}
.contact__form-label {
  color: #666;
  font-size: 1.4rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
  text-align: left;
}
.contact__form-input {
  color: #333;
  padding: 2rem;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 1.6rem;
  letter-spacing: 0px;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
  /* Code for Modern Browsers */
  /* Code for WebKit, Blink, Edge */
  /* Code for Internet Explorer 10-11 */
  /* Code for Microsoft Edge */
  /* Code for Mozilla Firefox 4 to 18 */
  /* Code for Mozilla Firefox 19+ */
}
.contact__form-input::placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-webkit-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-ms-input-placeholder {
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input:-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__form-input::-moz-placeholder {
  opacity: 1;
  color: #999;
  font-weight: 600;
  font-size: 1.6rem;
}
.contact__btn {
  padding: 2rem 4rem;
  font-size: 3rem;
  font-family: "distant_galaxyregular";
}
.contact__btn:hover {
  border: 2px solid #999;
}
.main-container {
  margin: auto;
  width: 100%;
  height: 100%;
  transition: transform 1s, opacity 1s;
  margin: 5rem auto 1rem auto;
}
.heading-sec__main {
  display: block;
  font-size: 4rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  position: relative;
}
.heading-sec__main--lt {
  color: #fff;
}
.heading-sec__sub {
  color: #fff;
  font-family: "Lato-regular";
  font-weight: 400;
  margin: auto 2rem auto 2rem;
  display: flex;
  justify-content: center;
}
/* ======================================
   About Section
   ====================================== */
.about-section h3,
.about-section h2 {
  font-family: "distant_galaxyregular";
  color: transparent;
  -webkit-text-stroke: 1px #333;
}

.about-section {
  background-color: #e0d1d1;
  z-index: 3;
  font-family: "Lato-medium";
  padding: 40px 20px;
}
.about-h2 {
  text-align: left;
  font-size: 10rem;
  margin-bottom: 1.25rem;
}
.about-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
  border-bottom: #eee solid 1px;
}
.introduction {
  font-size: 2rem;
  flex: 1;
  margin-right: 1rem;
  font-family: "Lato-medium";
}
.about-image {
  flex: 1;
}
.skills {
  display: flex;
  flex: 2;
  flex-wrap: wrap;
  order: 1;
}
.skills__skill {
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.6rem;
  background: rgba(153, 153, 153, 0.2);
  border-radius: 5px;
  font-weight: 600;
  color: #777;
}
.soft {
  /* background: rgba(8, 163, 42, 0.2); */
  background: #e6f53c;
}
.skills-section h3 {
  order: 2;
  flex: 1;
  text-align: left;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
  margin-right: 2rem;
  align-items: flex-end;
}
.skills-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.25rem;
  border-bottom: #eee solid 1px;
}
.about-skills-h3 {
  text-align: justify;
}

.about-skills-h3 span {
  display: block;
  text-align: center;
}

.approach,
.goals,
.personal-interests {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 6rem auto;
  border-bottom: #eee solid 1px;
}

.approach h3,
.personal-interests h3 {
  flex: 1;
  text-align: left;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}
.approach p,
.personal-interests p {
  flex: 2;
  font-size: 2rem;
  margin-left: 2rem;
}
.goals h3 {
  flex: 1;
  text-align: left;
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}
.goals p {
  flex: 2;
  font-size: 2rem;
  margin-right: 2rem;
}
.about-cta__btn-container {
  margin-top: 2rem;
  text-align: center;
  font-family: "distant_galaxyregular";
}
/* ======================================
   Project Section
   ====================================== */

.projects-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  background: url("../assets/images/giphy.webp") no-repeat center center;
  background-size: cover;
}

.coming-soon-banner,
.projects-section h2 {
  display: none;
}
/* ======================================
   Media Queries
   ====================================== */
@media only screen and (min-width: 56.25em) {
  .nav {
    max-height: 110px;
  }
  .home-hero {
    height: calc(100vh - 238px);
    min-height: 400px;
  }
  .header__content-other {
    display: flex;
  }
  .header__content-home {
    height: 14vh;
    overflow: hidden;
  }
  .header__logo-sub {
    font-size: 2.5rem;
  }
}
@media only screen and (min-width: 37.5em) {
  .header__main-ham-menu-cont {
    display: none;
    width: 3rem;
    padding: 2.2rem 0;
  }

  /* ======================================
   Order Header
   ====================================== */
  .nav-header {
    order: 2;
  }
  .header__main-ham-menu-cont {
    order: 5;
  }
  .header__logo-container {
    order: 0;
  }
  .home-hero__cta {
    order: 3;
  }
  .home-hero__social {
    order: 1;
  }
  .goals h3 {
    order: 2;
  }
  .goals p {
    order: 1;
  }
}

@media only screen and (max-width: 75em) and (min-width: 56.25em) {
  .home-hero {
    display: flex;
  }
  .avatar {
    width: 25%;
    height: 25%;
  }
}

@media only screen and (max-width: 56.25em) and (min-width: 37.5em) {
  /* 900px */
  body {
    font-size: 1rem;
  }
  .header {
    flex-direction: column;
    align-items: center;
  }
  .home-hero {
    height: calc(100vh - 100px);
  }

  .avatar {
    width: 14vw;
    height: 14vw;
  }

  .home-hero__cta {
    font-size: 16px;
  }
  .btn {
    font-size: 14px;
  }
  .nav {
    height: auto;
    padding: 0%;
  }
}
@media only screen and (max-width: 56.25em) {
  .main-footer__upper {
    padding: 1rem 0;
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .main-footer__lower {
    padding: 1.5rem 0;
  }
  .movable {
    display: none;
  }
}
@media only screen and (max-width: 37.5em) {
  #particle-js {
    flex: 2;
    height: 100vh;
  }
  body {
    display: flex;
    flex-direction: column;
  }
  .header {
    flex-direction: column;
    align-items: center;
    flex: 1;
  }
  .nav {
    padding: 0;
    flex: 0.5;
  }
  .home-hero__socials {
    justify-content: center;
    align-items: center;
  }
  .home-hero {
    flex: 2;
  }
  .home-hero__social {
    width: 30px;
    height: 30px;
  }
  .home-hero__social-icon-link {
    font-size: 100%;
    padding: 16%;
  }
  .header__sm-menu--active {
    visibility: visible;
    opacity: 1;
  }
  .nav__links {
    display: none;
  }
  .header__main-ham-menu-cont {
    display: block;
  }

  .avatar {
    width: 50%;
    height: 50%;
  }
  .header__content {
    height: 100%;
    padding: 0.5rem 2rem;
  }
  .header__logo-sub {
    font-size: 2.5rem;
  }
  .header__main-ham-menu-cont {
    display: block;
  }

  /* ======================================
   Contact form
   ====================================== */
  .contact__form-container {
    padding: 3rem;
  }
  /* ======================================
   Order header
   ====================================== */

  .header__main {
    order: 3;
  }
  .header__logo-container {
    order: 0;
  }
  .home-hero__cta {
    order: 2;
  }
  .home-hero__social {
    order: 1;
  }
  /* ======================================
  About Section 
   ====================================== */

  .approach,
  .goals,
  .personal-interests,
  .about-cta,
  .about-content,
  .skills-section {
    flex-direction: column;
    align-items: center;
  }

  .introduction,
  .about-image,
  .skills {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .about-h2,
  .introduction,
  .approach h3,
  .goals h3,
  .personal-interests h3,
  .about-cta h3 {
    text-align: center;
  }

  .skills {
    justify-content: center;
    order: 2;
    margin-right: 0;
  }
  .skills-section h3 {
    order: 1;
  }

  .approach h3,
  .approach p {
    text-align: center;
    margin-left: 0;
  }

  .approach p,
  .goals p,
  .personal-interests p,
  .about-cta p {
    text-align: center;
    margin: 0;
    margin-bottom: 1rem;
  }
}
/* ======================================
   COLORS
   ====================================== */
:root {
  --background-gradient: radial-gradient(
    circle at 12% 81%,
    rgba(0, 0, 0, 1),
    rgb(59, 51, 52),
    rgba(47, 12, 12, 1)
  );
}
